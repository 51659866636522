@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Satoshi", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

.width-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 12px 0 12px;
}

/* nav and mobile-nav */
.mobile-wrapper {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transform-origin: right center;
  transition: all 900ms ease-in-out, opacity 500ms linear,
    visibility 500ms ease-in;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: rgba(255, 255, 255, 0.9);
  opacity: 0.5;
  cursor: pointer;
}

.left0 {
  top: 0;
  left: 0;
}

.left-100 {
  top: -150%;
  left: 0;
}

.mobile-nav_content {
  width: 100%;
  background: #f9fafb;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  color: #000;
  position: relative;
  height: fit-content;
  padding-bottom: 30px;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
}

.mobile-menu {
  list-style-type: none;
  align-items: center;
}

.lds-default,
.lds-default div {
  box-sizing: border-box;
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 50px;
}
.lds-default div {
  position: absolute;
  width: 6.4px;
  height: 6.4px;
  background: #2c8ec2;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 36.8px;
  left: 66.24px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22.08px;
  left: 62.29579px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11.30421px;
  left: 51.52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7.36px;
  left: 36.8px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11.30421px;
  left: 22.08px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22.08px;
  left: 11.30421px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 36.8px;
  left: 7.36px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 51.52px;
  left: 11.30421px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62.29579px;
  left: 22.08px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66.24px;
  left: 36.8px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62.29579px;
  left: 51.52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 51.52px;
  left: 62.29579px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* PAGINATOR */
.custom-paginator {
  width: 100%;
  margin: 20px 0px 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  left: 0;
}

.custom-paginator ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-paginator ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.custom-paginator ul li a {
  text-decoration: none;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
}

.custom-paginator ul li.active {
  text-decoration: none;
  background: rgb(17, 24, 39, 1);
}

.custom-paginator ul li.active a {
  color: #ffffff;
}

.custom-paginator ul li.disabled {
  pointer-events: none;
}

.custom-paginator ul li.previous.disabled a {
  color: rgba(17, 24, 39, 0.25);
  pointer-events: none;
}

.custom-paginator ul li.next.disabled a {
  color: rgba(17, 24, 39, 0.25);
  pointer-events: none;
}

.custom-paginator ul li.previous a {
  color: rgb(17, 24, 39, 1);
}

.custom-paginator ul li.next a {
  color: rgb(17, 24, 39, 1);
}

/* table */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

td,
th {
  border: 0.7px solid #dddddd;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px;
  white-space: nowrap;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.table-header-light {
  background-color: rgba(156, 163, 175, 1);
}

.table-header-dark {
  background-color: #343a40;
}

/* PAGES TABLE BODY */
.table-responsive {
  margin-bottom: 0px;
  overflow-x: auto;
}

/* responsive nav */
@media screen and (min-width: 704px) {
  .navBarToggle {
    display: none;
  }
  .navMenu {
    display: flex;
  }
  .navMobileVisibility {
    display: none;
  }
}
